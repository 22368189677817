import {defineStore} from 'pinia'
import {emailErrorMsg, emptyErrorMsg, isEmpty, mailIsValid} from "@/validators/validators";
import axios from "axios";
import {ElLoading} from "element-plus";
import {useNotifyStore} from "@/store/notify/notify-store";

export const useContactStore = defineStore({
    id: 'contactStore',
    state: () => ({
        form: {
            firstname: '',
            lastname: '',
            company: '',
            email: '',
            message: '',
            honey: '',
            token: ''
        },
        errorMsg: ''
    }),
    getters: {},
    actions: {
        sendContactMail() {
            const notifyStore = useNotifyStore();

            if (this.form.honey === '') {
                if (isEmpty(this.form, ['honey'])) {
                    this.errorMsg = emptyErrorMsg;
                } else if (!mailIsValid(this.form.email)) {
                    this.errorMsg = emailErrorMsg
                } else {
                    let loading = ElLoading.service({fullscreen: true})
                    axios.post('contact/send-mail', {
                        form: this.form
                    }).then(() => {
                        loading.close()
                        this.clearForm();
                        notifyStore.notify('Erfolgreich', 'Deine Kontaktanfrage wurde erfolgreich versendet.')
                    }).catch(() => {
                        loading.close()
                        notifyStore.notify('Fehler', 'Versuche es noch einmal.', 'error')
                    })
                }
            } else {
                notifyStore.notify('Verdacht auf SPAM', 'Möglicher SPAM erkannt. Versuchen Sie es noch einmal.', 'error')
            }
        },
        setErrorMsg(msg) {
            this.errorMsg = msg;
        },
        clearForm() {
            this.errorMsg = ''
            let that = this
            Object.keys(this.form).map(function (key) {
                that.form[key] = ''
            });
        }
    },
})
