<template>
  <introduction>
    <template #title>Kontakt</template>
    <template #description>
      <span class="text-bm-font-gray-1">
        Hast Du noch Fragen? <br>
        Nutze dieses Formular!
      </span>
    </template>
  </introduction>

  <div class="flex justify-center py-12">
    <div class="fmu-container">
      <div class="fmu-error mb-10" v-if="contactStore.errorMsg">
        {{ contactStore.errorMsg }}
      </div>
      <div class="grid md:grid-cols-2 gap-2">
        <div>
          <label for="firstname" class="font-sans font-thin text-16px text-xl">Vorname</label>
          <input type="text" id="firstname" class="fmu-input" v-model="contactStore.form.firstname">
        </div>
        <div>
          <label for="lastname" class="font-sans font-thin text-16px text-xl">Nachname</label>
          <input type="text" id="lastname" class="fmu-input" v-model="contactStore.form.lastname">
        </div>
      </div>
      <div class="pt-3">
        <label for="company" class="font-sans font-thin text-16px text-xl">Firma</label>
        <input type="text" id="company" class="fmu-input" v-model="contactStore.form.company">
      </div>
      <div class="pt-3">
        <label for="email" class="font-sans font-thin text-16px text-xl">E-Mail</label>
        <input type="email" id="email" class="fmu-input" v-model="contactStore.form.email">
      </div>
      <div class="pt-3">
        <label for="message" class="font-sans font-thin text-16px text-xl">Nachricht</label>
        <textarea name="" id="message" cols="30" rows="10" class="fmu-input"
                  v-model="contactStore.form.message" maxlength="1000"></textarea>
        <input class="fmu-input ohno" type="text" name="phone" v-model="contactStore.form.honey">
      </div>
      <div class="font-sans font-thin">
        <small>{{ contactStore.form.message.length }} von 1000 Zeichen</small> <br>
        <small>Alle Felder sind Pflichtfelder</small>
      </div>
      <div class="mt-10">
        <vue-hcaptcha @verify="onVerify" sitekey="f37c4ce7-ff84-449d-a2f9-4c4d0ebb9d0b" ref="hcaptcha"></vue-hcaptcha>
      </div>
      <fmu-button class="float-right" @button-click="contactStore.sendContactMail">SENDEN</fmu-button>
    </div>
  </div>
</template>

<script>
import Introduction from "@/components/theme/introduction/Introduction";
import FmuButton from "@/components/theme/button/FmuButton";
import {useContactStore} from "@/store/contact/contact-store";
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

export default {
  name: 'Contact',
  components: {FmuButton, Introduction, VueHcaptcha},
  setup() {
    const contactStore = useContactStore();

    const onVerify = (token) => {
      contactStore.form.token = token;
    }

    return {contactStore, onVerify}
  }
}
</script>

<style scoped>
.ohno {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
</style>
